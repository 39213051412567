<template>
  <div class="app-container">
    <el-form ref="inboundForm" :model="inboundForm" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="出库单号">
              <el-input v-model="inboundForm.outboundCode" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号">
              <el-input v-model="inboundForm.orderId" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库类型">
              <el-select v-model="inboundForm.outboundType" :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option
                  v-for="item in getDictionariesOpt('VWMS_BOUND_TYPE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="调拨类型">
              <el-select v-model="inboundForm.transferType" :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option
                  v-for="item in getDictionariesOpt('VWMS_TRANSFER_TYPE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库仓库">
              <el-select v-model="inboundForm.outboundWarehouseCode" :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">
              <el-select v-model="inboundForm.outboundStatus" :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option v-for="item in getDictionariesOpt('VWMS_OUTBOUND_STATUS')" :key="item.val" :value="item.val" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="出库超时时间">
              <el-date-picker
                v-model="inboundForm.tranforbidDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="~"
                :start-placeholder="$t('title.starttime')"
                :end-placeholder="$t('title.endtime')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="出库时间">
              <div style="display:flex">
                <el-select v-model="inboundForm.tableSuffix" :placeholder="$t('page.selectPlaceholder')" style="margin-right: 20px;">
                  <el-option v-for="item in getDictionariesOpt('VWMS_OUTBOUND_YEAR')" :key="item.val" :value="item.val" :label="item.label" />
                </el-select>
                <el-date-picker
                  v-model="inboundForm.transportDate"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  :start-placeholder="$t('title.starttime')"
                  :end-placeholder="$t('title.endtime')"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :span="24" class="mb-3" style="display: flex">
          <el-button type="primary" @click="openExAdd">新增出库单</el-button>
          <el-button type="primary" style="margin-left: 10px;" @click="openSystem">
            导入第三方系统出库单
          </el-button>
        </el-row>

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="getOutboundTask(1)">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="outboundCode" label="出库单号" width="180" align="center">
        <template slot-scope="scope">
          <router-link :to="{ append:true, path: 'details', query: { row:JSON.stringify(scope.row) }}">
            <el-link :underline="false" type="primary">{{ scope.row.outboundCode }}</el-link>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="orderId" label="关联单号" width="120" align="center" />
      <el-table-column prop="transportId" label="运输单号" width="120" align="center">
        <template slot-scope="scope">{{ scope.row.transportId }}</template>
      </el-table-column>
      <el-table-column prop="outboundType" label="出库类型" width="120" align="center">
        <template slot-scope="scope">{{ getOutboundTypeLabel(scope.row.outboundType) }}</template>
      </el-table-column>
      <el-table-column prop="outboundType" label="调拨类型" width="120" align="center">
        <template slot-scope="scope">{{ getOutTransferTypeLabel(scope.row.transferType) }}</template>
      </el-table-column>
      <el-table-column prop="outboundWarehouseName" label="出库仓库" width="120" align="center" />
      <el-table-column prop="destinationWarehouseName" label="目的仓库" width="120" align="center" />
      <el-table-column prop="outboundOutTime" label="出库超时时间" width="120" align="center" />
      <el-table-column prop="outboundTime" label="出库时间" width="120" align="center" />
      <el-table-column prop="expectPairs" label="应出库数量" width="120" align="center" />
      <el-table-column prop="actualPairs" label="实出库数量" width="120" align="center" />
      <el-table-column prop="actualWeight" label="实出库重量（KG）" width="120" align="center" />
      <el-table-column prop="actualVolume" label="实出库体积（CBM）" width="120" align="center" />
      <el-table-column prop="outboundStatus" label="状态" width="120" align="center">
        <template slot-scope="scope">{{ getShipmentStatus(scope.row.outboundStatus) }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        :label="$t('page.operate')"
        width="150"
      >
        <!-- 退仓 (查看)  进仓&& 其他 (shipment)  进仓(没有终止出库) -->
        <template slot-scope="scope">
          <!-- 查看 -->
          <span v-if="scope.row.outboundStatus == '1' || scope.row.outboundStatus == '0'">
            <el-button type="text" size="small" @click="handleRelease(scope.row)">终止出库</el-button>
          </span>
          <span v-if="scope.row.outboundStatus == '0' && scope.row.outboundType == 'transfer'">
            <el-button type="text" size="small" @click="createShipment(scope.row)">生成Shipment</el-button>
          </span>
          <span>
            <!-- 查看 -->
            <router-link :to="{ append:true, path: 'details', query: { row:JSON.stringify(scope.row) }}">
              <el-button class="ml-2" type="text" size="small">查看</el-button>
            </router-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="update" />
    <el-dialog
      :close-on-click-modal="false"
      title="导入第三方系统出库单"
      :visible.sync="otherDialogVisible"
      width="600px"
      :before-close="handleClose"
      center
    >
      <el-form ref="form" :model="otherDialogForm" :rules="otherDialogRules" label-width="80px" style="display: flex;">
        <div class="el-lt" style="width:100%;">
          <el-row :span="24">
            <el-col :span="24">
              <el-form-item
                label="服务商"
                :class="$i18n.locale"
                :rules="{
                  required: true, message: '请选择', trigger: 'change'
                }"
              >
                <el-select v-model="platformStr" filterable placeholder="请选择">
                  <el-option v-for="item in getDictionariesOpt('VWMS_SERVICE_PROVIDER')" :key="item.val" :value="item.val + '&&' + item.label" :label="item.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-upload
                  ref="upload"
                  accept=".xlsx, .xls,.csv"
                  :headers="uploadHeaders"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleUploadSuccess"
                  :on-remove="handleFileRemove"
                  :on-progress="uploadFileProcess"
                  :on-change="changeFile"
                  action=""
                  :auto-upload="false"
                  drag
                  :limit="1"
                  :file-list="fileList"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__text">只能上传xlsx/xls/csv文件</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="otherDialogSubmit">导入</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增出库单"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleDiaClose"
      center
      class="add-dialog"
    >
      <el-form ref="rulesForm" :model="outboundForm" label-width="100px" style="display: flex;">
        <div class="el-lt" style="width:100%;">
          <el-row :span="24">
            <el-form-item label=" " :class="$i18n.locale" />

            <el-col :span="12">
              <el-form-item
                label="出库类型"
                :rules="[
                  { required: true, message: '请选择', trigger: 'change' }
                ]"
                :class="$i18n.locale"
                prop="outboundType"
              >
                <el-select
                  v-model="outboundForm.outboundType"
                  :placeholder="$t('page.selectPlaceholder')"
                  @change="outboundTypeChange"
                >
                  <el-option
                    v-for="item in getDictionariesOpt('VWMS_BOUND_TYPE')"
                    :key="item.val"
                    :value="item.val"
                    :label="item.label"
                    :disabled="item.val !=='transfer'"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="调拨类型"
                :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
                :class="$i18n.locale"
                prop="transferType"
              >
                <el-select
                  v-model="outboundForm.transferType"
                  :placeholder="$t('page.selectPlaceholder')"
                  @change="transferTypeChange"
                >
                  <el-option
                    v-for="item in getDictionariesOpt('VWMS_TRANSFER_TYPE')"
                    :key="item.val"
                    :value="item.val"
                    :label="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="出库仓库"
                :class="$i18n.locale"
                prop="outboundWarehouseCode"
                :rules="[
                  { required: true, message: '请选择', trigger: 'change'}
                ]"
              >
                <!-- // THIRD_PARTY  第三方仓   OWN自有仓  PLATFORM平台 -->
                <el-select
                  v-model="outboundForm.outboundWarehouseCode"
                  :placeholder="$t('page.selectPlaceholder')"
                  clearable
                  filterable
                  @change="boundWarehouseChange"
                >
                  <el-option
                    v-for="item in warehouseList"
                    :key="item.logicWarehouseCode"
                    :value="item.logicWarehouseCode"
                    :label="item.logicWarehouseName"
                    :disabled="(item.type==='exit' && (item.warehouseType === 'THIRD_PARTY' || item.warehouseType === 'OWN' )) || (item.type==='into'&& (item.warehouseType === 'PLATFORM' || item.warehouseType === 'OWN' )) || (item.type==='other' && (item.warehouseType === 'PLATFORM' || item.warehouseType === 'OWN' ) ) "
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="outboundForm.transferType === 'exit'"
                label="order-id"
                :rules="[
                  { required: true, message: '请选择', trigger: 'change' }
                ]"
                :class="$i18n.locale"
                prop="orderId"
              >
                <el-input v-model="outboundForm.orderId" placeholder="请输入order-id" />
              </el-form-item>
            </el-col>
            <el-col :span="12">

              <el-form-item
                v-if="outboundForm.outboundType == 'transfer'"
                label="目的仓库"
                :class="$i18n.locale"
                prop="destinationWarehouseCode"
                :rules="[
                  { required: true, message: '请选择', trigger: 'change' }
                ]"
              >
                <!-- {{ desWarehouseList }} -->
                <el-select
                  v-model="outboundForm.destinationWarehouseCode"
                  :placeholder="$t('page.selectPlaceholder')"
                  :disabled="!outboundForm.outboundWarehouseCode"
                  filterable
                  clearable
                  @change="sendestinationWare"
                >
                  <!-- 进仓 && (第三方 ||  (自由 && !NJ || 自由 !CHI )) -->
                  <el-option
                    v-for="item in desWarehouseList"
                    :key="item.logicWarehouseCode"
                    :value="item.logicWarehouseCode"
                    :disabled="item.logicWarehouseCode === outboundForm.outboundWarehouseCode ||
                      (item.type==='exit' && (item.warehouseType === 'PLATFORM' || (item.warehouseType === 'OWN' && (!['TOP_US_TOP_02','TOP_US_TOP_01'].includes(item.logicWarehouseCode)))))
                      || (item.type==='into' && (item.warehouseType === 'THIRD_PARTY' || (item.warehouseType === 'OWN'&& (!['TOP_US_TOP_01'].includes(item.logicWarehouseCode)))) )
                      || (item.type==='other' && item.warehouseType === 'PLATFORM' )"
                    :label="item.logicWarehouseName"
                  />
                  <!-- && (item.warehouseType === 'OWN' && (!['TOP_US_TOP_01','TOP_US_TOP_02'].includes(item.logicWarehouseCode)) -->
                </el-select>
              </el-form-item>
            </el-col>
            <!-- >{{ item.logicWarehouseName }} {{ item.type }} {{ item.warehouseType }}</el-option> -->
            <el-col :span="24">
              <el-form-item
                v-if="outboundForm.outboundType == 'transfer'"
                label="平台・站点・店铺"
                :rules="[
                  { required: true, message: '请选择', trigger: 'change' }
                ]"
              >
                <div class="dial_inp">
                  <el-select
                    v-model="outboundForm.platformCode"
                    class="full-width"
                    :placeholder="$t('page.selectPlaceholder')"
                    value-key="platformCode"
                    :disabled="!outboundForm.destinationWarehouseCode"
                    clearable
                    filterable
                    @change="seletPlatformChange"
                  >
                    <el-option
                      v-for="item in sendSellPlatformList"
                      :key="`${item.platformCode}+${item.siteCode}+${item.shopCode}`"
                      :value="item"
                      :label="`${item.platformCode}+${item.siteCode}+${item.shopCode}`"
                    />
                  </el-select>
                  <el-input v-model="outboundForm.siteCode" disabled />
                  <el-input v-model="outboundForm.shopCode" disabled />
                </div>
              </el-form-item>

              <!-- 店铺 // 只有目的仓为平台仓 才为展示 -->
              <!-- <el-form-item
                v-if="isShowShop && outboundForm.outboundType == 'transfer'"
                label="店铺"
                :class="$i18n.locale"
                prop="shopCodeGroup"
                :rules="[
                  { required: true, message: '请选择', trigger: 'change' }
                ]"
              >
                <div class="dial_inp">
                  <el-select
                    v-model="outboundForm.sendSellPlatformId"
                    class="full-width"
                    :placeholder="$t('page.selectPlaceholder')"
                    clearable
                    @change="sendSellPlatformChange"
                  >
                    <el-option
                      v-for="item in sendSellPlatformList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.platformShortName"
                    />
                  </el-select>
                  <el-select
                    v-model="outboundForm.sendSiteCode"
                    class="full-width"
                    :placeholder="$t('page.selectPlaceholder')"
                    clearable
                    @change="sendSiteCodeChange"
                    @visible-change="_querySiteList"
                  >
                    <el-option
                      v-for="item in siteData"
                      :key="item.id"
                      :value="item.siteCode + '/' + item.id"
                      :label="item.siteName"
                    />
                  </el-select>
                  <el-select
                    v-model="outboundForm.shopCodeGroup"
                    class="full-width"
                    :placeholder="$t('page.selectPlaceholder')"
                    clearable
                    @visible-change="_getShopList"
                  >
                    <el-option v-for="list in shopList" :key="list.id" :value="`${list.shopCode}&&${list.siteCode}`" :label="list.shopCode" />
                  </el-select>
                </div>
              </el-form-item> -->
              <el-form-item
                v-show="outboundForm.outboundType !== 'destroy'"
                label="收货地址"
                :class="$i18n.locale"
                prop="postCode"
                :rules="[
                  { required: isAddressRequired, message: '请填写完整', trigger: 'blur' }
                ]"
              >
                <div class="dial_inp">
                  <el-input v-model="outboundForm.country" placeholder="请输入国家" />
                  <el-input v-model="outboundForm.state" placeholder="请输入州/省" />
                  <el-input v-model="outboundForm.city" placeholder="请输入城市/区" />
                  <!-- <el-input v-model="outboundForm.county" placeholder="请输入区/县" /> -->
                  <el-input v-model="outboundForm.address" placeholder="请输入详细地址" />
                  <el-input v-model="outboundForm.houseNumber" placeholder="请输入门牌号" />
                  <el-input v-model="outboundForm.postCode" placeholder="请输入邮编" />
                </div>
              </el-form-item>
              <el-form-item
                v-show="outboundForm.outboundType !== 'destroy'"
                label="收货人"
                :class="$i18n.locale"
                prop="name"
                :rules="[
                  { required: isAddressRequired, message: '请填写完整', trigger: 'blur' }
                ]"
              >
                <div class="dial_inp">
                  <el-input v-model="outboundForm.name" placeholder="请输入收件人姓名" />
                  <el-input v-model="outboundForm.telephone" placeholder="请输入电话" />
                  <el-input v-model="outboundForm.mail" placeholder="请输入邮箱" />
                </div>
              </el-form-item>
              <el-form-item
                prop="fileListNum"
                label="出库商品"
              >
                <el-upload
                  ref="upload"
                  accept=".xlsx, .xls"
                  :headers="uploadHeaders"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleUploadSuccess"
                  :on-remove="handleOutFileRemove"
                  :on-progress="uploadFileProcess"
                  :on-change="fileOutChange"
                  action=""
                  :auto-upload="false"
                  drag
                  :limit="1"
                >

                  <i class="el-icon-upload" />
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__text">只能上传xlsx/xls文件</div>
                  <!-- <el-input v-show="false" v-model="fileListNum" /> -->
                </el-upload>
                <el-link type="primary" :underline="false" @click="downTemplate('VWMS_OUTBOUND_KEY')">模板下载</el-link>

              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDiaClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('rulesForm')">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { getPageOutboundTask, outboundShipmentImport, findDownloadUrl,
  outboundImport, getTerminationOutbound, queryTterraceList, querySiteList,
  getShopList, addShipment, getChannelItem } from '@/api/stock-out'
import { getWarehouse } from '@/api/stockin'
import Paging from '@/components/Paging'
import { Mixin } from '@/mixin/mixin.js'
import { downloads } from '@/utils'

export default {
  name: '',
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      isShowShop: false, // 只有目的仓为平台仓 才为展示
      isAddressRequired: true, // 如果目的仓库为FBA仓，收货地址和收货人任然为必填 否则
      sendSellPlatformList: [], // 平台列表
      siteData: [], // 站点列表
      shopList: [], // 店铺列表
      seletWareHouseObj: {},
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      action: process.env.VUE_APP_UPLOAD_API,
      oterFile: '',
      fileListNum: '',
      warehouseList: [],
      platformCode: '',
      platformStr: '',
      otherDialogForm: {
        platformCode: ''
      },
      otherDialogRules: {
        region: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      outFileList: [],
      fileList: [],
      tableDatas: [],
      inboundForm: {
        shipmentId: '',
        outboundType: '',
        tableSuffix: new Date().getFullYear(),
        transportDate: [],
        tranforbidDate: []
      },
      outboundForm: {
        outboundType: 'transfer',
        sendSellPlatformId: '',
        shopCodeGroup: '',
        destinationWarehouseCode: '',
        sendSiteCode: '',
        platformCode: '',
        siteCode: '',
        shopCode: '',
        channelCode: '',
        country: '',
        state: '',
        city: '',
        address: '',
        houseNumber: '',
        postCode: '',
        name: '',
        telephone: '',
        mail: '',
        destinationWarehouseType: ''
      },
      showRow: false, // 切换显示input
      tableLoading: false,
      otherDialogVisible: false,
      dialogVisible: false,
      destinationWarehouseType: {},
      pager: {
        size: 20,
        current: 1,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      const { transportDate = [], tranforbidDate = [] } = this.inboundForm
      const [outboundTimeStart, outboundTimeEnd] = transportDate || []
      const [outboundOutTimeStart, outboundOutTimeEnd] = tranforbidDate || []
      return Object.assign({}, this.pager, this.inboundForm, { outboundTimeStart: outboundTimeStart ? outboundTimeStart + ' 00:00:00' : '',
        outboundTimeEnd: outboundTimeEnd ? outboundTimeEnd + ' 23:59:59' : '', transportDate: [],
        outboundOutTimeStart, outboundOutTimeEnd, tranforbidDate: [] })
    },
    desWarehouseList() {
      // transfer sell
      console.log(this.seletWareHouseObj)
      const type = this.outboundForm.outboundType
      switch (type) {
        // case 'transfer':
        //   return this.warehouseList.filter(v => this.seletWareHouseObj.cargoOwnerCode === v.cargoOwnerCode)
        case 'sell':
          return this.warehouseList.filter(v => this.seletWareHouseObj.cargoOwnerCode !== v.cargoOwnerCode)
        default:
          return this.warehouseList
      }
    },
    getShopListParams() {
      const { sendSellPlatformId, sendSiteCode } = this.outboundForm
      const siteCode = sendSiteCode.split('/')[0]
      const sellPlatformCode = this.sendSellPlatformList.find(item => item.id === sendSellPlatformId)
      return { sellPlatformCode: sellPlatformCode && sellPlatformCode.platformCode || '', siteCode }
    }
  },
  mounted() {
    this.getOutboundTask()
    this._queryWarehouseList()
    this._queryTterraceList() // 发至平台
  },
  methods: {
    outboundTypeChange(val) {
    },
    async otherDialogSubmit() {
      console.log(this)
      const { platformStr } = this
      if (!platformStr || !this.fileList[0]) {
        this.$message({
          type: 'warning',
          message: '请选择服务商或文件上传完整'
        })
        return
      }
      const arr = platformStr.split('&&') || []
      this.$confirm(`当前导入的是：${arr[1] || ''}出库单，确认导入？`, this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        try {
          const formDate = new FormData()
          formDate.append('file', this.fileList[0])
          formDate.append('platformCode', arr[0] || '')
          await outboundShipmentImport(formDate)
          this.getOutboundTask(1)
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.otherDialogVisible = false
        } catch (error) {
          console.log(error)
          this.clearFiles()
        }
      })
    },
    fileOutChange(file, fileList) {
      if (this.beforeAvatarUpload(file)) {
        this.outFileList = file && file.raw ? [file.raw] : []
      }
    },
    resetClick(val) { // 点击重置清空文本框信息
      this.inboundForm = {
        transferType: '',
        outboundCode: '',
        orderId: '',
        outboundType: '',
        outboundWarehouseCode: '',
        outboundStatus: '',
        tableSuffix: new Date().getFullYear(),
        transportDate: [],
        tranforbidDate: []
      }
      this.getOutboundTask(1)
    },

    transferTypeChange() {
      if ((this.outboundForm.outboundWarehouseCode || this.outboundForm.destinationWarehouseCode) !== '') {
        this.outboundForm.outboundWarehouseCode = ''
        this.outboundForm.destinationWarehouseCode = ''
      }
      if (this.outboundForm.transferType !== 'exit') {
        this.outboundForm.orderId = ''
      }

      this._queryWarehouseList()
    },

    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    handleClose(done) {
      this.otherDialogVisible = false
      this.fileList = []
      this.platformStr = ''
    },
    openSystem() {
      this.otherDialogVisible = true
    },
    openExAdd() {
      this.dialogVisible = true
    },
    handleDiaClose(done) {
      this.dialogVisible = false
      this.isShowShop = false
      this.outboundForm = {
        shopCodeGroup: '',
        sendSiteCode: '',
        shopCode: '',
        destinationWarehouseCode: '',
        outboundType: 'transfer',
        sendSellPlatformId: '',
        country: '',
        state: '',
        city: '',
        address: '',
        houseNumber: '',
        postCode: '',
        name: '',
        telephone: '',
        mail: '',
        orderId: '',
        transferType: ''
      }
      this.outFileList = []
      this.clearFiles()
    },
    async getOutboundTask(flag) {
      try {
        this.loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getPageOutboundTask(this.queryParams)
        this.pager = pager
        this.tableDatas = records
      } finally {
        this.loading = false
      }
    },
    // 终止出库
    handleRelease({ outboundCode }) {
      this.$confirm('终止任务', this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        await getTerminationOutbound({
          outboundCode,
          tableSuffix: this.inboundForm.tableSuffix
        })
        this.getOutboundTask(1)
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      })
    },
    //
    createShipment(row) { // 生成 Shipment
      if ((row.destinationWarehouseCode).includes('AMAZON') || (row.destinationWarehouseCode).includes('amazon')) {
        this.$router.push({
          path: 'amazon-shipment',
          append: 'true',
          query: { row: JSON.stringify(row) }
        })
      } else {
        this._insterShipmentAndPick(row)
      }
    },
    async _insterShipmentAndPick({ outboundCode, tableSuffix }) {
      const { msg } = await addShipment({
        outboundCode,
        tableSuffix
      })
      if (msg) {
        this.getOutboundTask()
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      }
    },
    update(val) {
      this.pager = val
      this.getOutboundTask()
    },
    getShipmentStatus(status) {
      switch (status + '') {
        case '0':
          return '已生效'
        case '1':
          return '待出库'
        case '2':
          return '已出库'
        case '3':
          return '缺量出库'
        case '4':
          return '终止出库'
        default:
          return ''
      }
    },
    getOutboundType(status) {
      switch (status + '') {
        case 'sell':
          return '销售出库'
        case 'transfer':
          return '调拨出库'
        case 'destroy':
          return '销毁出库'
        default:
          return ''
      }
    },
    beforeAvatarUpload(file) {
      const name = file.name.split('.')
      // const isLt500K = file.size / 1024 < 500
      const isApk = name[name.length - 1] === 'xlsx' || name[name.length - 1] === 'xls' || name[name.length - 1] === 'csv'
      if (!isApk) {
        this.clearFiles()
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '只能上传不超过500kb的文件,请重新上传'
        })
      }
      return isApk
    },
    clearFiles() {
      this.$refs.upload.clearFiles()
    },
    uploadFileProcess(event, file, fileList) {
      console.log(event, file, fileList)
    },
    handleUploadSuccess(res) {
    },
    handleFileRemove() {
      this.fileList = []
    },
    handleOutFileRemove() {
      this.outFileList = []
    },
    changeFile(file, fileList) {
      if (this.beforeAvatarUpload(file)) {
        this.fileList = file && file.raw ? [file.raw] : []
      }
    },
    async downTemplate(key) {
      const { datas } = await findDownloadUrl(key)
      downloads(datas)
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
      this.warehouseList.map(e => {
        if (this.outboundForm.transferType === 'exit') {
          e.type = this.outboundForm.transferType
        } else if (this.outboundForm.transferType === 'into') {
          e.type = this.outboundForm.transferType
        } else if (this.outboundForm.transferType === 'other') {
          e.type = this.outboundForm.transferType
        } else {
          e.type = ''
        }
      })
    },
    boundWarehouseChange(code) {
      console.log(this.outboundForm.transferType)
      if (['', undefined].includes(this.outboundForm.transferType)) {
        this.outboundForm.outboundWarehouseCode = ''
        this.$message.warning('先选择调拨类型')
        return
      }
      Array.isArray(this.warehouseList) && this.warehouseList.map(v => {
        if (code === v.logicWarehouseCode) {
          this.seletWareHouseObj = v
        }
      })
      this.outboundForm = Object.assign(this.outboundForm, {
        destinationWarehouseCode: '',
        country: '',
        state: '',
        city: '',
        address: '',
        houseNumber: '',
        postCode: '',
        name: '',
        telephone: '',
        mail: '',
        destinationWarehouseType: ''
      })
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const {
            outboundType,
            outboundWarehouseCode,
            destinationWarehouseCode,
            address,
            houseNumber,
            mail,
            name,
            postCode,
            telephone,
            country,
            state,
            city,
            channelCode,
            platformCode,
            shopCode,
            siteCode,
            orderId,
            transferType
          } = this.outboundForm
          if (!this.outFileList[0]) {
            this.$message({
              type: 'warning',
              message: '请选择出库商品文件上传完整'
            })
            return
          }
          const { warehouseList } = this
          const params = {}
          Array.isArray(warehouseList) && warehouseList.map(v => {
            if (outboundWarehouseCode === v.logicWarehouseCode) {
              Object.assign(params, {
                outboundWarehouseName: v.logicWarehouseName,
                outboundPlatformCode: v.platformCode,
                sourceWarehouseCode: v.sourceWarehouseCode,
                cargoOwnerCode: v.cargoOwnerCode,
                logicWarehouseCode: v.logicWarehouseCode,
                groupId: v.groupIds && v.groupIds[0],
                channelCode: v.channelCode
              })
            }
            if (outboundType === 'transfer' && destinationWarehouseCode === v.logicWarehouseCode) {
              Object.assign(params, {
                destinationWarehouseName: v.logicWarehouseName,
                destinationWarehouseId: v.groupIds && v.groupIds[0] || '',
                destinationWarehouseCode: v.logicWarehouseCode,
                destinationPlatformCode: v.platformCode
              })
            }
          })
          const formDate = new FormData()
          formDate.append('file', this.outFileList[0])
          formDate.append('outboundType', outboundType) // 出库类型
          formDate.append('groupId', params.groupId) //	出货仓组合id
          formDate.append('outboundWarehouseCode', outboundWarehouseCode) // 出货仓code
          formDate.append('outboundWarehouseName', params.outboundWarehouseName) // 出货仓名称
          formDate.append('warehouseCode', params.sourceWarehouseCode) // 出货仓主仓code
          formDate.append('channelCode', params.channelCode) // 渠道code
          formDate.append('outboundPlatformCode', params.outboundPlatformCode) // 出库仓平台代码
          // formDate.append('platformCode', this.getShopListParams.sellPlatformCode || params.destinationPlatformCode) // 平台代码
          formDate.append('cargoOwnerCode', params.cargoOwnerCode) // 货主code
          // formDate.append('tableSuffix', this.inboundForm.tableSuffix) // 明细表后缀(年份)
          if (outboundType === 'transfer') { // 调拨出库
            formDate.append('destinationWarehouseCode', params.destinationWarehouseCode) // 目的仓code
            formDate.append('destinationWarehouseName', params.destinationWarehouseName) // 目的仓名称
            if (!shopCode) {
              this.$message({
                message: '请选择店铺',
                type: 'warning'
              })
              return false
            }

            formDate.append('shopCode', shopCode || '') // 店铺代码
            formDate.append('platformCode', platformCode || '') // 站点代码
            formDate.append('siteCode', siteCode || '') // 站点代码
            formDate.append('destinationChannelCode', channelCode || '') // 站点代码
            formDate.append('orderId', orderId || '') // 站点代码
            formDate.append('transferType', transferType || '') // 站点代码
            formDate.append('destinationWarehouseType', this.destinationWarehouseType.warehouseType || '') // 目的仓类型
            formDate.append('outboundWarehouseType', this.seletWareHouseObj.warehouseType || '') // 出库仓类型
            // const arr = shopCodeGroup && shopCodeGroup.split('&&') || []
            // if (arr.length) {

            // }
          }
          if (outboundType !== 'destroy') {
            // const outboundAddress = {

            //   tableSuffix: this.inboundForm.tableSuffix,
            //   address,
            //   city,
            //   country,
            //   houseNumber,
            //   mail,
            //   name,
            //   postCode,
            //   state,
            //   telephone
            // }
            // console.log('%c 🍊 outboundAddress: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', outboundAddress);
            // formDate.append('outboundAddress', JSON.stringify(outboundAddress))
            formDate.append('address', address || '') // 详细地址
            formDate.append('city', city || '') // 城市
            formDate.append('country', country || '') //	国家
            formDate.append('houseNumber', houseNumber || '') // 门牌号
            formDate.append('mail', mail || '') // 邮箱
            formDate.append('name', name || '') // 姓名
            formDate.append('postCode', postCode || '') // 邮编
            formDate.append('state', state || '') // 州/省
            formDate.append('telephone', telephone || '') // 电话
          }
          try {
            const { msg } = await outboundImport(formDate)

            if (msg) {
              this.handleDiaClose()
              this.getOutboundTask(1)
              this.$notify({
                title: msg,
                message: msg,
                type: 'success'
              })
            }
          } catch (error) {
            this.clearFiles()
          }
        }
      })
    },
    // 平台列表
    async _queryTterraceList(e) {
      try {
        this.selectLoading = true
        const { datas } = await queryTterraceList({
          platformType: 'transfer'
        })
        this.sendSellPlatformList = datas
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.selectLoading = false
      }
    },
    sendSellPlatformChange() { // 切换发至平台
      this.siteData = []
      this.shopList = []
      this.outboundForm.sendSiteCode = ''
      this.outboundForm.shopCode = ''
      this.outboundForm.shopCodeGroup = ''
    },
    seletPlatformChange(item) { // 切换发至平台
      this.outboundForm.platformCode = item.platformCode
      this.outboundForm.shopCode = item.shopCode
      this.outboundForm.siteCode = item.siteCode
      this.outboundForm.channelCode = item.channelCode
    },
    sendestinationWare(logicWarehouseCode) {
      this.outboundForm = Object.assign(this.outboundForm, {
        sendSellPlatformId: '',
        country: '',
        sendSiteCode: '',
        shopCodeGroup: '',
        state: '',
        city: '',
        address: '',
        houseNumber: '',
        postCode: '',
        name: '',
        telephone: '',
        mail: '',
        siteCode: '',
        shopCode: '',
        channelCode: '',
        platformCode: ''
      })
      this.destinationWarehouseType = this.warehouseList.find(e => e.logicWarehouseCode === logicWarehouseCode)
      console.log(this.destinationWarehouseType)
      Array.isArray(this.warehouseList) && this.warehouseList.map(v => {
        if (v.logicWarehouseCode === logicWarehouseCode) {
          this.isShowShop = v.warehouseType === 'PLATFORM'
          this.isAddressRequired = !(v.platformCode === 'AMAZON')
          const obj = v.addressJson && JSON.parse(v.addressJson) || {}
          Object.assign(this.outboundForm, {
            country: obj.country,
            state: obj.state,
            city: obj.city,
            mail: obj.mail,
            address: obj.address,
            houseNumber: obj.houseNumber,
            name: obj.name,
            postCode: obj.postCode,
            telephone: obj.telephone

          })
        }
      })

      this._getChannelItem(logicWarehouseCode)
    },
    sendSiteCodeChange() { // 切换发至站点
      this.shopList = []
      this.outboundForm.shopCode = ''
      this.outboundForm.shopCodeGroup = ''
    },
    async _querySiteList(e) {
      if (!e) return
      if (!this.outboundForm.sendSellPlatformId) {
        this.$message({
          message: '操作成功',
          type: 'warning'
        })
        return
      }
      try {
        this.selectLoading = true
        const { datas } = await querySiteList({ sellPlatformId: this.outboundForm.sendSellPlatformId })
        this.siteData = datas
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.selectLoading = false
      }
    },
    async _getShopList(visible) {
      if (typeof visible === 'boolean' && !visible) return
      if (!this.outboundForm.sendSiteCode) {
        this.$message.warning('先选择站点')
        return
      }
      try {
        this.selectLoading = true
        const { datas } = await getShopList(this.getShopListParams)
        this.shopList = datas
      } finally {
        this.selectLoading = false
      }
    },
    async _getChannelItem(val) {
      try {
        const { datas } = await getChannelItem({ logicWarehouseCode: val })
        console.log('%c 🍻 datas: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', datas)
        this.sendSellPlatformList = datas
      } finally {
        // this.selectLoading = false
      }
    }

  }
}
</script>

<style lang="scss" scoped>

.add-dialog {
  /deep/ .el-input {
      margin-bottom: 10px;
    }
}
.dial_inp {
  display: flex;
  flex-wrap: wrap;
  > div:nth-child(2n) {
    margin-left: 10px;
  }
  > div {
    width: 216px;
  }
}
</style>

